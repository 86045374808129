import { IRouterConfig, lazy } from 'ice';
import BasicLayout from '@/layouts/BasicLayout';

const Dashboard = lazy(() => import('@/pages/Dashboard'));
const Profile = lazy(() => import('@/pages/Profile'));
const Wish = lazy(() => import('@/pages/Wish'));
// const Bounty = lazy(() => import('@/pages/Bounty'));
const Account = lazy(() => import('@/pages/Account'));
const Governance = lazy(() => import('@/pages/Governance'));
const OspExtension = lazy(() => import('@/pages/OspExtension'));
const Osp = lazy(() => import('@/pages/Osp'));
const Setting = lazy(() => import('@/pages/Setting'));
const routerConfig: IRouterConfig[] = [
  {
    path: '/',
    component: BasicLayout,
    children: [
      { path: '/', exact: true, component: Dashboard },
      { path: 'Profile', component: Profile },
      { path: 'Wish', component: Wish },
      // { path: 'Bounty', component: Bounty },
      { path: 'Account', component: Account },
      { path: 'Governance', component: Governance },
      { path: 'OspExtension', component: OspExtension },
      { path: 'Osp', component: Osp },
      { path: 'Setting', component: Setting },
    ],
  },
];

export default routerConfig;
