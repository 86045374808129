const headerMenuConfig = [];

const asideMenuConfig = [
  { name: 'Dashboard', path: '/', icon: 'smile' },
  { path: 'Account', name: 'Account' },
  { path: 'Governance', name: 'Governance' },
  { path: 'Profile', name: 'Profile' },
  { path: 'Wish', name: 'Wish' },
  { path: 'Osp', name: 'Osp' },
  { path: 'OspExtension', name: 'Osp Extension' },
  { path: 'Setting', name: 'Setting' },
];

export { headerMenuConfig, asideMenuConfig };
