import { ZeekClient } from '@keccak256-evg/zeek-client';
import { createContext, useContext } from 'react';
import {OspClient} from "osp-js";

export const ZeekWalletContext = createContext<{client: null | ZeekClient, init: (priKey: string, jwtToken: string, env: string) => void, ospClient: null | OspClient, ospLoggedIn: boolean }>({ client: null, init: (priKey: string, jwtToken: string, env: string) => {}, ospClient: null, ospLoggedIn: false});

export const useZeekWallet = () => {
  const {client, init, ospClient, ospLoggedIn} = useContext(ZeekWalletContext)
  if (!client) {
    throw new Error('Zeek client not init')
  }
  return {client, init, ospClient, ospLoggedIn}
}
