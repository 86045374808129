
import {EnvConfig} from '@keccak256-evg/zeek-client';

export const PRIVATE_KEY: string = "PRIVATE_KEY";
export const JWT_TOKEN: string = "JWT_TOKEN";
export const ENV: string = "ENV";

export async function quickLogin(): Promise<string> {
  const currentEnv:string = localStorage.getItem(ENV) as string;

  const jwtToken = await fetch(EnvConfig[currentEnv].remoteUrl.concat('/v2/customer/login/quick'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: 'tokenType=3&token=12&pTokenType=web&deviceId=1234567890ABCDEF&autoRegister=true'
  })
    .then(response => response.json())
    .then(data => {
      return data.obj.jwtKey;
    })
    .catch(error => {
      console.error("quickLogin error, ", error)
      throw new Error('quickLogin error')
    });
  localStorage.setItem(JWT_TOKEN, jwtToken);
  return jwtToken;
}

export async function getPolicyData(userOperation: UserOperationVo) {
  const currentEnv:string = localStorage.getItem(ENV) as string;
  const jwtToken = localStorage.getItem(JWT_TOKEN) as string;

  const policyData: string = await fetch(EnvConfig[currentEnv].remoteUrl.concat('/v1/contract/gas/policy'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'JWT_TOKEN': jwtToken,
      'saas_id': 'talent'
    },
    body: JSON.stringify(userOperation)
  })
    .then(response => response.json())
    .then(data => {
      return data.obj.policy_data;
    })
    .catch(error => {
      console.error("getPolicyData error, ", error)
      throw new Error('getPolicyData error')
    });
  return policyData;
}

export type UserOperationVo = {
  paymasterAndData?: string;
  callGasLimit?: string;
  initCode?: string;
  signature?: string;
  callData?: string;
  verificationGasLimit?: string;
  baseFeePerGas?: string;
  nonce?: string;
  sender?: string;
  maxPriorityFeePerGasNoBuff?: string;
  maxPriorityFeePerGas?: string;
  maxFeePerGas?: string;
  preVerificationGas?: string;
  maxFeePerGasNoBuff?: string;
};
