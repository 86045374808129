import React, {useEffect, useState} from 'react';
import {Shell, ConfigProvider, Button, Select} from '@alifd/next';
import PageNav from './components/PageNav';
import Footer from './components/Footer';
import {WalletKitProvider} from '@mysten/wallet-kit';
import {quickLogin} from '../../apis/ZeekBackend';

import {EnvConfig, Environment, ZeekClient, ZeekEnvironment} from '@keccak256-evg/zeek-client';
import {ethers} from 'ethers';
import {ZeekWalletContext} from '@/hooks/useZeekWallet';
import {CHAIN_NAMESPACES, IProvider} from '@web3auth/base';
import {Web3Auth} from '@web3auth/modal';
import EthereumRpc from '@/ethersRPC';
import {getPolicyData, UserOperationVo} from '@/apis/ZeekBackend';
import {OspClient, Environment as OspEnvironment} from "@open-social-protocol/osp-client";

const testClientId = "BOlvW9_5mCDeBQ6hFrh8lyPZ-ReoBGEXnQjWj6-l_8g-VM1TqEHNaYEXAvK5Af15uPVgMzJREdWzUttbO9tcuAs";
const testNetwork = 'testnet';
const mainNetClientId = "BP1-7cFu-_jnqwexx8ER7y2z_kjrXSg3acY2SqyHEXacerW5VSawMt-av0wlNCPQi6YsYm-lfiXOkltW4zDwiYs";
const mainNetwork = 'sapphire_mainnet';

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
};

export default function BasicLayout({children}: { children: React.ReactNode }) {
  const [ospClient, setOspClient] = useState<null | OspClient>(null);
  const [client, setClient] = useState<ZeekClient>();
  const [walletAddress, setWalletAddress] = useState('');
  const [currentEnv, setCurrentEnv] = useState('');

  const [web3auth, setWeb3auth] = useState<Web3Auth>(
    new Web3Auth({
      clientId: testClientId,
      chainConfig,
      web3AuthNetwork: testNetwork,
    }),
  );
  const [, setProvider] = useState<IProvider | null>(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [ospLoggedIn, setOspLoggedIn] = useState(false);

  const PRIVATE_KEY: string = 'PRIVATE_KEY';
  const JWT_TOKEN: string = 'JWT_TOKEN';
  const ENV: string = 'ENV';
  const GUEST_ID: string = 'GUEST_ID';

  async function store(priKey: string, env: string) {
    const defaultPriKey = '27dba6f1dca01ad1d7045a433f87d32e75abf05b6a50f86f47da73848eb63438';
    const defaultEnv = Environment.alpha.toString();

    if (priKey) {
      localStorage.setItem(PRIVATE_KEY, priKey);
    }
    const privateKeyCache = localStorage.getItem(PRIVATE_KEY);
    if (!privateKeyCache) {
      localStorage.setItem(PRIVATE_KEY, defaultPriKey);
    }

    if (env) {
      localStorage.setItem(ENV, env);
    }
    const envCache = localStorage.getItem(ENV);
    if (!envCache) {
      localStorage.setItem(ENV, defaultEnv);
    }

    const tokenCache = localStorage.getItem(JWT_TOKEN) as string;
    if (!tokenCache) {
      await quickLogin();
    }
  }

  async function init(priKey: string, env: string) {
    await store(priKey, env);

    const pk: string = localStorage.getItem(PRIVATE_KEY) as string;
    const token: string = localStorage.getItem(JWT_TOKEN) as string;
    const currentEnv: string = localStorage.getItem(ENV) as string;

    const rpcProvider = ethers.getDefaultProvider(EnvConfig[currentEnv].remoteUrl);
    const environment: Environment = Environment[currentEnv as keyof typeof Environment];

    console.log('wallet init start, private key:', pk, ',currentEnv:', currentEnv);
    const signer = new ethers.Wallet(pk, rpcProvider);

    const zeekEnvironment = await ZeekEnvironment.create({
      env: environment,
      getHeaders: async () => {
        return {
          headers: {
            jwt_token: `${token}`,
            saas_id: 'zeek',
          },
        };
      }
    });

    zeekEnvironment.chainConfig.rpcUrl;
    // const abc = await zeekEnvironment.init();
    // console.log('abc---------', zeekEnvironment);


    const ospClient = await initOspClient(priKey, signer, environment, zeekEnvironment.chainConfig.osp.appId);

    const zeekClient = new ZeekClient({
      environment: zeekEnvironment,
      signer: signer,
      accountAddress: await ospClient?.authentication?.getAccountAddress()
    });


    console.log('zeek zeekClient', zeekClient);
    setClient(zeekClient);
  }

  async function initOspClient(priKey: string, signer: ethers.Wallet, env: Environment, ospAppId: string) {
    const policyMiddleWare: any = async function (userOperation, opt) {
      if (!opt) opt = {};

      if (
        opt.paymasterAndData
        && opt.paymasterAndData != '0x'
        || opt.policy_data
      ) {
        return [userOperation, opt];
      }

      // 请求接口
      const policyData = await getPolicyData({
        ...userOperation,
        nonce: userOperation?.nonce?.toString(),
      } as UserOperationVo);

      opt.policy_data = policyData;
      return [userOperation, opt];
    };

    // const ospEnv = OspEnvironment[OspEnvironment.alpha];
    const ospEnv = OspEnvironment[env.toString() as keyof typeof OspEnvironment];
    const guestId = await getGuestId();
    const client = await OspClient.create({
      env: env === Environment.prod ? OspEnvironment.pre : ospEnv,
      storage: localStorage,
      app_id: ospAppId,
      guest_id_marketing: guestId.toString()
    });

    client.txClient.addMiddleware(policyMiddleWare, 150);
    setOspClient(client);
    await client?.authentication?.login(signer);
    setOspLoggedIn(await client?.authentication?.isLogin());
    setWalletAddress(await client?.authentication?.getAccountAddress());
    console.log("ospClient init end,", client);
    return client;
  }

  async function getGuestId() {
    let _guestId = await localStorage.getItem(GUEST_ID);

    if (!_guestId) {
      _guestId = Math.floor(Math.random() * (9223372036854775 - 1 + 1) + 1).toString();
      await localStorage.setItem(GUEST_ID, _guestId)
    }
    return Number(_guestId);
  }

  const initWeb3auth = async (env: string) => {
    try {
      const web3auth = new Web3Auth({
        clientId: env.includes('pre') || env.includes('prod') ? mainNetClientId : testClientId,
        chainConfig,
        web3AuthNetwork: env.includes('pre') || env.includes('prod') ? mainNetwork : testNetwork,
      });
      setWeb3auth(web3auth);
      console.log(web3auth);

      await web3auth.initModal();
      setProvider(web3auth.provider);

      if (web3auth.connected) {
        setLoggedIn(true);
      }
    } catch (error) {
      console.error('initWeb3auth error', error);
    }
  };

  useEffect(() => {
    init('', '');
    const cachedEnv = localStorage.getItem(ENV) as string;
    setCurrentEnv(cachedEnv);
    initWeb3auth(cachedEnv);
  }, []);

  const login = async () => {
    const web3authProvider = await web3auth.connect();
    if (!web3authProvider) {
      throw new Error('Web3auth connect fail')
    }
    setProvider(web3authProvider);
    if (web3auth.connected) {
      setLoggedIn(true);
    }
    await quickLogin();

    const wallet = new EthereumRpc(web3authProvider);
    const privateKey = await wallet.getPrivateKey();
    if (privateKey) {
      localStorage.setItem(PRIVATE_KEY, privateKey);
    }

    await init(privateKey, '');
    console.log('web3auth login end');
  };

  const logout = async () => {
    if (web3auth.connected) {
      await web3auth.logout();
      setLoggedIn(false);
    }
    localStorage.removeItem(PRIVATE_KEY);
    console.log('web3auth logout end');
    // window.location.reload();
  };

  const envOptions = Object.keys(Environment).map((key) => ({
    value: Environment[key],
    label: key,
  }));

  async function handleChangeEnv(env: string) {
    await logout();
    setCurrentEnv(env);
    localStorage.setItem(ENV, env);
    await quickLogin();
    await init('', env);
    initWeb3auth(env);
    // window.location.reload()
  }

  if (!client) {
    return <div>Loading...</div>;
  }

  return (
    <ZeekWalletContext.Provider value={{client, init, ospClient, ospLoggedIn}}>
      <WalletKitProvider>
        <ConfigProvider>
          <Shell
            style={{
              minHeight: '100vh',
            }}
            type="brand"
            fixedHeader={false}
          >
            <Shell.Branding>
              {/* <Logo
              image="https://img.alicdn.com/tfs/TB1.ZBecq67gK0jSZFHXXa9jVXa-904-826.png"
              text="Logo"
            /> */}
            </Shell.Branding>
            <Shell.Navigation
              direction="hoz"
              style={{
                marginRight: 10,
              }}
            />
            <Shell.Action>
              <label
                style={{
                  width: '360px',
                }}
              >
                {walletAddress}
              </label>

              <Select
                onChange={(value) => handleChangeEnv(value)}
                style={{
                  marginRight: '20px',
                  width: '200px',
                }}
                value={currentEnv}
              >
                {envOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
              <Button onClick={loggedIn ? logout : login}>{loggedIn ? 'Logout' : 'Web3Auth Login'}</Button>
            </Shell.Action>
            <Shell.Navigation>
              <PageNav/>
            </Shell.Navigation>

            <Shell.Content>{children}</Shell.Content>
            <Shell.Footer>
              <Footer/>
            </Shell.Footer>
          </Shell>
        </ConfigProvider>
      </WalletKitProvider>
    </ZeekWalletContext.Provider>
  );
}
